import React, { Component, Suspense } from 'react'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import SingleInput from './SingleInput'

// npm install node-sass@4.14.1
import './App.scss';

const Verbale = React.lazy(() => import('./Verbale'))


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adi: '',
      targa: '',
      errorMessage: null,
      recapResponse: true,
    };
  }


  handleAdiChange = (e) => {
    this.setState({ adi: e.target.value.replace(/[^0-9]/gi, '') });
  }

  handleTargaChange = (e) => {
    this.setState({ targa: e.target.value.replace(/[^0-9a-z]/gi, '').toUpperCase() })
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { adi, targa, recapResponse } = this.state
    // console.log('recapResponse', recapResponse)
    if (!recapResponse) {
      console.log('recaptcha Response is null!')
      this.setState({
        verbale: null,
        errorMessage: 'Cliccare sul controllo di sicurezza reCAPTCHA.',
      })
      this.recaptchaInstance.reset()
      return
    }

    axios.get(`/dati?adi=${adi}&plate=${targa}&recapResponse=${recapResponse}`)
      .then((result) => {
        // console.log(result.data)
        this.setState({ verbale: result.data, errorMessage: null })
      })
      .catch((error) => {
        if (error.response) {
          console.log('axios error:', error.response.data)
          if (error.response.data === 'no-match'
            || error.response.data === 'ADI-NaN'
            || error.response.data === 'adi-range-error'
            || error.response.status === 500
          ) {
            this.setState({
              verbale: null,
              errorMessage: 'Nessun verbale trovato. Controllare i dati inseriti o rivolgersi alla Polizia Locale.',
            })
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('*******', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('---- Error', error.message, error);
        }
      })
    this.recaptchaInstance.reset()
  }


  handleClearForm = (e) => {
    e.preventDefault();
    this.recaptchaInstance.reset()
    this.setState({
      adi: '',
      targa: '',
      recapResponse: null,
      verbale: null,
      errorMessage: null,
    })
  }

  onCaptchaChange = (value) => {
    // console.log("Captcha value:", value)
    this.setState({ recapResponse: value})
  }


  render() {

    const {
      adi, targa, verbale, errorMessage,
    } = this.state
    // console.log('qui verbale', verbale)
    return (
      <React.Fragment>
        <h2 id="title-title">Visura immagini sanzionamenti elettronici</h2>
        <form id="visuraImmagini" method="post" onSubmit={this.handleFormSubmit}>
          <fieldset>
            <legend>
              Ricerca
              {/* <span>(tutti i campi sono obbligatori)</span> */}
            </legend>
            <div>
              <SingleInput
                id='numeroADI'
                inputType="text"
                title="Numero ADI"
                name="numeroADI"
                controlFunc={this.handleAdiChange}
                content={adi}
                placeholder="123456"
              />
            </div>
            <div>
              <SingleInput
                inputType="text"
                title="Targa veicolo"
                name="targa"
                controlFunc={this.handleTargaChange}
                content={targa}
                placeholder="AB123CD"
              />
            </div>
            <ReCAPTCHA
              ref={ e => this.recaptchaInstance = e}
              sitekey="6LcRoEwUAAAAAEv-DNE201A_3u6DHKlDe7fVyFRS"
              onChange={this.onCaptchaChange}
              hl="it"

            />


            <div className="bottoni">
              <input onClick={this.handleClearForm} name="clear" type="button" id="cancella" value="Cancella i dati" />
              <input name="ricerca" value="Cerca immagini" type="submit" id="invia" tabIndex="-1" />
            </div>
            {errorMessage
              && <div className="errore">{errorMessage}</div>
            }
          </fieldset>

          {verbale &&
          <fieldset>
            <legend>
              Risultato

            </legend>
            <Suspense fallback={<div>Caricamento in corso...</div>}>
              <Verbale
                tipo={verbale.tipo}
                dataora={verbale.dataora}
                velocita_ril={verbale.velocita_ril}
                velocita_cons={verbale.velocita_cons}
                sito={verbale.sito}
                adi={verbale.adi}
                targa={verbale.targa}
                gateType={verbale.gateType}
              />
            </Suspense>

          </fieldset>
          }
        </form>

        <p className="postilla">
          Si ricorda che l&apos;accesso fraudolento ad immagini per le quali il richiedente non sia persona interessata
          è sanzionato in base a quanto previsto all&apos;art 76 - Norme penali - del D.P.R. 445 del 28/12/2000.
        </p>
      </React.Fragment>
    )
  }
}

export default App

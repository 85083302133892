import React from 'react'
import PropTypes from 'prop-types'

const SingleInput = ({
  title, name, inputType, content, controlFunc, placeholder,
}) => (
  <div className="form-group">
    <label htmlFor="i1" className="form-label">{title}</label>
    <input
      id="i1"
      className="form-input"
      name={name}
      type={inputType}
      value={content}
      onChange={controlFunc}
      placeholder={placeholder}
    />
  </div>
);

SingleInput.propTypes = {
  inputType: PropTypes.oneOf(['text', 'number']).isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  placeholder: PropTypes.string,
}

export default SingleInput
